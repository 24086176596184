// Extended from Bootstrap
// Body
body {
    -webkit-font-smoothing: antialiased;
}

// Links
a {
    color: $link-color;
    text-decoration: none;
    &:hover {
        color: $link-hover-color;
        text-decoration: none;
    }
}

hr {
    background-color: $gray-400;
}