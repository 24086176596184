// lightpick datepicker

.lightpick {
  position: absolute;
  z-index: 99999;
  padding: 4px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  color: #32303a;
  font-family: $font-family-sans-serif;
  line-height: 1.125em;
}

.lightpick--inlined {
  position: relative;
  display: inline-block;
}

.lightpick,
.lightpick *,
.lightpick::after,
.lightpick::before {
  box-sizing: border-box;
}

.lightpick.is-hidden {
  display: none;
}

.lightpick__months {
  display: grid;
  background-color: #eee;
  grid-template-columns: auto;
  grid-gap: 1px;
}

.lightpick--2-columns .lightpick__months {
  grid-template-columns: auto auto;
}

.lightpick--3-columns .lightpick__months {
  grid-template-columns: auto auto auto;
}

.lightpick--4-columns .lightpick__months {
  grid-template-columns: auto auto auto auto;
}

.lightpick--5-columns .lightpick__months {
  grid-template-columns: auto auto auto auto auto;
}

.lightpick__month {
  padding: 4px;
  width: 288px;
  background-color: #fff;
}

.lightpick__month-title-bar {
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
  align-items: center;
}

.lightpick__month-title {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 4px;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  cursor: default;
  padding: 0 4px;
  border-radius: 4px;
}

.lightpick__month-title > .lightpick__select {
  border: none;
  background-color: transparent;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.lightpick__month-title > .lightpick__select:disabled {
  color: #333;
}

.lightpick__month-title > .lightpick__select-months {
  font-weight: bold;
  font-size: 1em;
  margin-right: 0.5em;
}

.lightpick__toolbar {
  display: flex;
  text-align: right;
  justify-content: flex-end;
}

.lightpick__previous-action,
.lightpick__next-action,
.lightpick__close-action {
  display: flex;
  margin-left: 6px;
  width: 32px;
  height: 32px;
  outline: none;
  border: 1px solid $gray-300;
  border-radius: 50%;
  background-color: transparent;
  justify-content: center;
  align-items: center;
}

.lightpick__previous-action,
.lightpick__next-action {
  font-size: 12px;
}

.lightpick__close-action {
  font-size: 18px;
}

.lightpick__previous-action:active,
.lightpick__next-action:active,
.lightpick__close-action:active {
  color: $primary;
  border-color: $primary;
}

.lightpick__days-of-the-week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 10px;
}

.lightpick__day-of-the-week {
  display: flex;
  font-size: 13px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}

.lightpick__days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.lightpick__day {
  display: flex;
  height: 34px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 13px;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.lightpick__day.is-today {
  color: $primary;
}

.lightpick__day:not(.is-disabled):hover {
  background-size: contain;
  background-color: $primary;
  color: $white;
  border-radius: 6px;
}

.lightpick__day.is-disabled {
  opacity: 0.38;
  pointer-events: none;
}

.lightpick__day.disabled-tooltip {
  pointer-events: auto;
}

.lightpick__day.is-disabled.is-forward-selected {
  opacity: 1;
}

.lightpick__day.is-disabled.is-forward-selected:not(.is-start-date) {
  background-color: rgba(38, 139, 210, 0.1);
  background-image: none;
}

.lightpick__day.is-previous-month,
.lightpick__day.is-next-month {
  opacity: 0.38;
}

.lightpick__day.lightpick__day.is-in-range:not(.is-disabled) {
  opacity: 1;
}

.lightpick__day.is-in-range {
  border-radius: 0;
  background-color: rgba(38, 139, 210, 0.1);
  background-image: none;
}

.lightpick__day.is-in-range:hover {
  background-color: $primary;
}

.lightpick__day.is-start-date.is-in-range,
.lightpick__day.is-end-date.is-in-range.is-flipped {
  border-top-left-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 6px;
  background-color: $primary;
  background-image: none;
}

.lightpick__day.is-end-date.is-in-range,
.lightpick__day.is-start-date.is-in-range.is-flipped {
  border-top-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 0;
  background-color: $primary;
  background-image: none;
}

.lightpick__day.is-start-date.is-end-date {
  background-color: $primary;
}

.lightpick__day.is-start-date,
.lightpick__day.is-end-date,
.lightpick__day.is-start-date:hover,
.lightpick__day.is-end-date:hover {
  background-color: $primary;
  background-size: auto;
  background-position: center;
  color: #fff;
  font-weight: bold;
}

.lightpick__tooltip {
  position: absolute;
  margin-top: -4px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  font-size: 11px;
  pointer-events: none;
}

.lightpick__tooltip::before {
  position: absolute;
  bottom: -5px;
  left: calc(50% - 5px);
  border-top: 5px solid rgba(0, 0, 0, 0.12);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: "";
}

.lightpick__tooltip::after {
  position: absolute;
  bottom: -4px;
  left: calc(50% - 4px);
  border-top: 4px solid #fff;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
}

.lightpick__footer {
  display: flex;
  justify-content: flex-end;
}

.lightpick__reset-action,
.lightpick__apply-action {
  border-radius: 5px;
  font-size: 12px;
  border: none;
}

.lightpick__reset-action {
  color: $primary;
  background-color: transparent;
  text-decoration: underline;
}

.lightpick__apply-action {
  color: $black;
  background-color: transparent;
  text-decoration: underline;
}
