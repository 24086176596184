.irs {}

.irs--flat {
    .irs-line {
        background-color: $gray-200;
        height: 3px;
        top: 30px;
    }
    .irs-bar {
        height: 3px;
        background-color: $primary;
        top: 30px;
    }
    .irs-handle>i:first-child {
        position: absolute;
        display: block;
        top: 0;
        left: 50%;
        width: 18px;
        height: 100%;
        margin-left: -1px;
        background-color: #ffffff;
        border-radius: 100%;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    }
    .irs-handle.state_hover>i:first-child,
    .irs-handle:hover>i:first-child {
        background-color: $primary;
    }
    .irs-from,
    .irs-to,
    .irs-single {
        color: $dark;
        font-size: 13px;
        line-height: 1.333;
        text-shadow: none;
        padding: 5px 9px;
        background-color: transparent;
        border: 1px solid $gray-200;
        border-radius: 4px;
        margin-left: 7px;
    }
    .irs-from:before,
    .irs-to:before,
    .irs-single:before {
        display: none;
    }
    .irs-min,
    .irs-max {
        display: none;
    }
    .irs-grid-pol {
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 18px;
        background: $gray-200;
    }
    .irs-grid-pol.small {
        height: 9px;
    }
    .irs-grid-text {
        position: absolute;
        bottom: -7px;
        left: 0;
        white-space: nowrap;
        text-align: center;
        font-size: 12px;
        line-height: 14px;
        padding: 0px 3px;
        color: $gray-600;
    }
}

.irs-from,
.irs-to,
.irs-single {
    position: absolute;
    display: block;
    top: -12px;
    left: 0;
    cursor: default;
    white-space: nowrap;
}