// Color system
$white: #fff !default;
$gray-100: #F4F4F5 !default;
$gray-200: #EAE9EC !default;
$gray-300: #D5D4D9 !default;
$gray-400: #AAA8B3 !default;
$gray-500: #bbb9cc !default;
$gray-600: #6F6985 !default;
$gray-700: #59536D !default;
$gray-800: #565266 !default;
$gray-900: #261F44 !default;
$black: #261F44 !default;
$grays: () !default;
$primary: #FF5938 !default;
$secondary: #672EBB !default;
$success: #24a148 !default;
$info: #17a2b8 !default;
$warning: #ffc107 !default;
$danger: #EA4336 !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;
$light-primary: #FFEAE6 !default; // NEW
$light-danger: #FFEBEA !default; // NEW
$light-info: #D0FFFF !default; // NEW
$light-warning: #FFF6E1 !default; // NEW
$light-secondary:#EBDDFF !default; // NEW
$light-success:#E8FFF3 !default; // NEW
$white: $white !default;
$theme-colors: ( "primary": $primary, "secondary": $secondary, "success": $success, "info": $info, "warning": $warning, "danger": $danger, "light": $light, "dark": $dark, "white": $white, "light-primary": $light-primary, "light-danger": $light-danger, "light-info": $light-info, "light-warning": $light-warning, "light-secondary": $light-secondary, "light-success": $light-success) !default;
// Typography
//
// Font, line-height, and color for body text, headings, and more.
// stylelint-disable value-keyword-case
$font-family-sans-serif: "Muli",
"sans-serif;" !default;
$font-family-monospace: "Muli",
"sans-serif;" !default;
$font-family-base: $font-family-sans-serif !default;
$font-family-dm-serif: "DM Serif Display",
"sans-serif;" !default;
// Font Awesome
$font-awesome: "Font Awesome 5 Free";
$font-family-heading: $font-family-sans-serif;
// stylelint-enable value-keyword-case
$font-size-base: 1rem !default; // Assumes the browser default, typically `14px`
$font-size-lg: 1.07rem !default;
$font-size-sm: 0.875rem !default;
$font-weight-lighter: 200 !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: 800 !default;
$font-weight-base: $font-weight-medium !default;
$line-height-base: 1.6 !default;
$line-height-lg: 2 !default;
$line-height-sm: 1.08 !default;
// Typography
$h1-font-size: $font-size-base * 2.625 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.625 !default;
$h4-font-size: $font-size-base * 1.25 !default;
$h5-font-size: $font-size-base * 1 !default;
$display-font-weight: 700 !default;
$lead-font-weight: 500 !default;
$h6-font-size: 0.813rem !default;
$headings-font-family: $font-family-heading !default;
$headings-font-weight: 700 !default;
$headings-line-height: 1.3 !default;
$headings-color: $black !default;
$link-hover-decoration: none !default;
// Body
//
// Settings for the `<body>` element.
$body-bg: $white !default;
$body-color: $gray-600 !default;
// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge((0: 0, 1: ($spacer * 0.25), // 4px
2: ($spacer * 0.5), // 8px
3: ($spacer * 1), // 16px
4: ($spacer * 1.5), // 32px
5: ($spacer * 2), // 40px 
6: ($spacer * 2.5), // 48px
7: ($spacer * 3), // 56px
8: ($spacer * 3.5), // 64px
9: ($spacer * 4), // 72px
10: ($spacer * 4.5), // 80px
11: ($spacer * 5), // 88px
12: ($spacer * 5.5), // 96px
13: ($spacer * 6), // 128px
14: ($spacer * 6.5), // 160px
15: ($spacer * 7), // 200px
16: ($spacer * 7.5), // 224px
17: ($spacer * 8), // 256px
18: ($spacer * 10), // 288px
19: ($spacer * 12), // 320px
20: ($spacer * 14), 21: ($spacer * 18.5), 22: ($spacer * 22), 23: ($spacer * 35), 24: ($spacer * 40), ), $spacers); // 384px
// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge((25: 25%, 50: 50%, 40: 40%, 75: 75%, 100: 100%, auto: auto), $sizes);
// borders
$border-radius: 0.25rem !default;
$card-border-color:$gray-200;
$border-color: $gray-200 !default;
// input
$input-btn-padding-y: 0.6rem !default;
$input-btn-padding-x: 1rem !default;
$input-btn-font-family: null !default;
$input-btn-font-size: 1rem !default;
$input-btn-padding-y-sm: 0.48rem !default;
$input-btn-padding-x-sm: 0.945rem !default;
$input-border-color: $gray-200 !default;
$input-btn-padding-y-lg: 0.63rem !default;
$input-btn-padding-x-lg: 1.56rem !default;
$input-bg: $white !default;
$input-btn-line-height: $line-height-base !default;
// form label
$form-label-margin-bottom: .5rem !default;
$form-label-font-size: .875rem !default;
$form-label-font-style: normal !default;
$form-label-font-weight: 500 !default;
$form-label-color: $dark !default;
// btn
$btn-font-weight: 700 !default;
$btn-soft-bg-opacity: .1 !default;
//badge
$badge-padding-y: .4rem !default;
$badge-padding-x: .5rem !default;
$badge-font-size: 75% !default;
$badge-font-weight:500 !default;
$badge-soft-bg-opacity: .1 !default;
// pagination
$pagination-padding-y: .4rem !default;
$pagination-padding-x: .78rem !default;
$pagination-padding-y-sm: 0.5rem !default;
$pagination-padding-x-sm: 0.938rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$enable-responsive-font-sizes: true;
// Avatar
$avatar-size-xs: 1.25rem !default;
$avatar-size-sm: 1.875rem !default;
$avatar-size-md: 2.5rem !default;
$avatar-size-lg: 3.125rem !default;
$avatar-size-xl: 4.375rem !default;
$avatar-size-xxl: 5.625rem !default;
// Icon
$icon-size-xs: 1.25rem !default;
$icon-size-sm: 1.875rem !default;
$icon-size-md: 2.5rem !default;
$icon-size-lg: 3.125rem !default;
$icon-size-xl: 4.375rem !default;
$icon-size-xxl: 5.625rem !default;
// table
$table-cell-padding-y: .9rem !default;
$table-cell-padding-x: .8rem !default;
// z index
$zIndex-1: 1;
$badge-soft-bg-opacity: .1 !default;
// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-reduced-motion: true !default;
$enable-grid-classes: true !default;
$enable-button-pointers: true !default;
$enable-rfs: true !default;
$enable-validation-icons: true !default;
$enable-negative-margins: true !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities: true !default;