    /*
Theme: Coach - Online Coaching Bootstrap Theme
Product Page: https://easetemplate.com/html/coach
*/
    
    // Muli Fonts 
    @import url("https://fonts.googleapis.com/css?family=PT+Serif:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap");
    // Font Awesome 
    @import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css");
    // Flat Icon
    @import url("fonts/essential-font/flaticon.css");
    // Flat Icon
    @import url("fonts/feather/feather.css");
    // DM Serif
    @import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap');
    // Bootstrap functions
    @import "../../node_modules/bootstrap/scss/functions";
    // User Variables 
    @import "user-variables";
    // theme variables
    @import "theme/variables";
    // Bootstrap
    @import "../../node_modules/bootstrap/scss/bootstrap";
    // Components
    @import "theme/components";
    // User
    @import "user";

    // https://react-popup.elazizi.com/getting-started#include-the-component
    @import 'reactjs-popup/dist/index.css';
