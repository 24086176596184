// 
// user.scss
// Use this to write your custom SCSS
//

.hero-section {
    background: url(./images/hero-slide.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.program-1 {
    background-image: url(./images/program-img-1.jpg);
}

.program-2 {
    background-image: url(./images/program-img-2.jpg);
}

.title {
    a, .h4 {
        color: $secondary;
    }
}

.certifications {
    text-align: center;
}

.btn-play:hover {
    cursor: pointer;
}

.hover-pointer:hover {
    cursor: pointer;
}

.other-modal {
    font-size: 12px;
}

.other-modal {
    .header {
        border-bottom: 1px solid gray;
        font-size: 18px;
        text-align: center;
        padding: 5px;
    }
}
.other-modal {
    .content {
        padding: 0;
        margin-bottom: -10px;
    }
    
}

.other-modal {
    .actions {
        padding: 10px 5px;
        margin: auto;
        text-align: center;
    }
}

.other-modal {
    .close {
        cursor: pointer;
        position: absolute;
        display: block;
        padding: 2px 5px;
        line-height: 20px;
        right: -10px;
        top: -10px;
        font-size: 24px;
        background: #ffffff;
        border-radius: 18px;
        border: 1px solid #cfcece;
    }
}

.popup-content {
    padding: 0;
    border: 0;
    background-color: transparent;
}

.card.contact-me {
    box-shadow: 0px 0px 14px 3px;
}

a.decorate:hover {
    text-decoration: underline;
}

.lead blockquote {
    background-color: $light-primary;
    color: $gray-800;
    opacity: 0.88;
    padding: 0.5em;
    border-radius: $border-radius;

    footer {
        text-align: right;
        padding-top: 1em;
        font-weight: bold;
    }
}
