// button
// Primary Link
.btn-primary-link {
    font-weight: $font-weight-bold;
    color: $primary;
    text-decoration: none;
    border-bottom: 2px solid $primary;
    &:hover {
        color: #ff3912;
        text-decoration: none;
        border-bottom: 2px solid #ff3912;
    }
    &:focus,
    &.focus {
        text-decoration: none;
        box-shadow: none;
    }
    &:disabled,
    &.disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
    }
}

// Secondary link
.btn-secondary-link {
    font-weight: $font-weight-bold;
    color: $secondary;
    text-decoration: none;
    border-bottom: 2px solid $secondary;
    &:hover {
        color: #56269c;
        text-decoration: none;
        border-bottom: 2px solid #56269c;
    }
    &:focus,
    &.focus {
        text-decoration: none;
        box-shadow: none;
    }
    &:disabled,
    &.disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
    }
}

// Soft variants
@mixin btn-variant-soft($bg, $color) {
    background-color: $bg;
    color: $color;
    &:hover {
        background-color: fade-in($bg, 0.05);
        color: $color;
    }
}

@each $color,
$value in $theme-colors {
    .btn-#{$color}-soft {
        @include btn-variant-soft(rgba($value, $btn-soft-bg-opacity), $value);
    }
}

// btn play
.btn-play {
    background-color: $white;
    color: $primary;
    transition: 0.2s ease;
    &:hover {
        background-color: $primary;
        color: $white;
        transition: 0.2s ease;
    }
}

// btn group
.nav.btn-group>.btn,
.btn-group-vertical>.btn {
    flex: 0;
}

.nav.btn-group {
    .btn-white {
        &.active {
            background-color: $primary;
            border-color: $primary;
            color: #fff;
        }
        &:focus {
            box-shadow: none !important;
        }
    }
}

// btn like
.btn-like {
    border: 1px solid $gray-200;
    background-color: transparent;
    height: 32px;
    width: 32px;
    display: block;
    text-align: center;
    border-radius: 100%;
    font-size: 14px;
    line-height: 34px;
    cursor: pointer;
    transition: .2s ease-in;
    color: $gray-300;
    &:hover {
        background-color: $primary;
        color: $white;
        border-color: $primary;
        transition: .2s ease-in;
    }
}