// Custom shape

// bg shape

.bg-shape {
  position: relative;

  &:after {
    position: absolute;
    background: url(../images/curve-shape.svg);
    background-size: cover;
    background-repeat: no-repeat;
    bottom: -15px;
    height: 62px;
    width: 100%;
    content: "";
    background-position-x: center;
  }
}

// Top shape

.top-shape {
  position: relative;

  &:after {
    position: absolute;
    background: url(../images/curve-shape-top.svg);
    background-size: cover;
    background-repeat: no-repeat;
    top: -36px;
    height: 90px;
    width: 100%;
    content: "";
    background-position-x: center;
  }
}


// Bottom shape

.bottom-shape {
  position: relative;

  &:after {
    position: absolute;
    background: url(../images/curve-shape-bottom.svg);
    background-size: cover;
    background-repeat: no-repeat;
    top: 0px;
    height: 77px;
    width: 100%;
    content: "";
    background-position-x: center;
  }
}


// bg pattern

.bg-pattern {
  position: relative;
  &:before {
    position: absolute;
    background: url(../images/pattern-2.svg);
    background-size: cover;
    background-repeat: repeat;
    top: 0px;
    height: 100%;
    width: 100%;
    content: "";
    opacity: .5;
  
    
    background-position-x: center;
  }
}

// bg left slant shape

.left-slant-shape {
  position: relative;
  &:before {
    position: absolute;
    background: url(../images/rectangle-top-shape.svg);
    background-size: cover;
    background-repeat: no-repeat;
    top: 0px;
    height: 100px;
    width: 100%;
    content: "";  
    background-position-x: center;
  }
}

// bg left slant shape

.right-slant-shape {
  position: relative;
  &:after {
    position: absolute;
    background: url(../images/rectangle-bottom-shape.svg);
    background-size: cover;
    background-repeat: no-repeat;
    bottom: 0px;
    height: 80px;
    width: 100%;
    content: "";  
    background-position-x: center;
    
  }
}

// spark shape

.spark-shape- {
  position: relative;
  &:after {
    position: absolute;
    background: url(../images/rectangle-bottom-shape.svg);
    background-size: cover;
    background-repeat: no-repeat;
    bottom: 0px;
    height: 80px;
    width: 100%;
    content: "";  
    background-position-x: center;
    
  }
}