// Docs
// Docs header
.docs-header {
    .docs-header-btn {
        display: flex;
        align-items: center;
    }
    .navbar {
        padding: 8px 12px;
        border-bottom: 1px solid #efeef1;
        border-top: 3px solid $primary;
        .navbar-brand {
            padding: 0px;
        }
        .dropdown-menu {
            margin: 0px;
            border: 1px solid rgb(239, 238, 241);
        }
        .navbar-nav {
            .nav-item {
                .nav-link {
                    font-size: 16px;
                    line-height: 28px;
                    padding: 14px;
                    margin-bottom: 0px;
                    color: $gray-700;
                    &:hover {
                        color: $black;
                    }
                }
            }
        }
    }
}

// Docs Sidebar
.docs-nav-sidebar {
    position: fixed;
    width: 220px;
    height: 100% !important;
    top: 56px;
    overflow: auto;
    font-weight: 500;
    background-color: $white;
    overflow: hidden;
    z-index: 22;
    border-right: 1px solid #efeef1;
    .docs-nav {
        overflow-y: auto;
    }
    .navbar {
        padding: 10px;
    }
    .navbar-nav {
        min-width: 100%;
        >.nav-item {
            >.nav-link {
                color: $black;
                font-size: 14px;
                font-family: $font-family-sans-serif;
                line-height: 28px;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                &:hover {
                    color: $primary;
                }
                &.active {
                    color: $primary;
                }
            }
            >.nav-link[data-toggle="collapse"] {
                &:after {
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    font-family: $font-awesome;
                    font-weight: 700;
                    content: "\f105";
                    margin-left: auto;
                    color: $gray-300;
                    -webkit-transition: all 0.2s ease;
                    -o-transition: all 0.2s ease;
                    transition: all 0.2s ease;
                }
            }
            >.nav-link[data-toggle="collapse"][aria-expanded="true"] {
                &:after {
                    color: $primary;
                    -webkit-transform: rotate(90deg);
                    -ms-transform: rotate(90deg);
                    transform: rotate(90deg);
                }
            }
        }
        .nav {
            .nav-item {
                .nav-link {
                    color: $gray-700;
                    font-size: 14px;
                    line-height: 16px;
                    padding: 6px 20px;
                    font-family: $font-family-sans-serif;
                    &:hover {
                        color: $primary;
                    }
                }
                .nav-link.active {
                    color: $primary;
                }
            }
        }
    }
}

// Docs right sidebar 
.sidebar-nav-fixed {
    position: fixed;
    top: 3rem;
    height: calc(100vh - 4rem);
    overflow-y: auto;
    margin-top: 65px;
    ul {
        list-style: none;
        padding-left: 16px;
        li {
            a {
                font-size: 13px;
                color: $gray-600;
                display: block;
                padding: 2px 12px;
                background: transparent;
                border-radius: 4px;
                line-height: 23px;
                text-decoration: none;
                &:hover {
                    color: $primary;
                }
            }
            a.active {
                display: block;
                color: $primary;
                background: transparent !important;
                text-decoration: none;
            }
        }
    }
}

// Doc section head
.doc-section-head {
    border-bottom: 1px solid $gray-200;
    padding: 40px 0px;
    margin-bottom: 40px;
}

// Docs section head text 
.doc-section-head-text {
    font-size: 20px;
    line-height: 32px;
}

// Docs main wrapper
.docs-main-wrapper {
    min-height: 100%;
    position: relative;
}

.docs-wrapper {
    position: relative;
    left: 0;
    margin-left: 220px;
    min-height: 100vh;
    background-color: $gray-100;
    overflow: hidden;
    top: 35px;
}

.docs-content {
    padding: 0px 60px 140px 60px;
}

.docs-content-head {
    padding: 70px 0px;
}

// Copy Button
.copy-button {
    cursor: pointer;
    border: 0;
    font-size: 13px;
    line-height: 18px;
    text-transform: capitalize;
    font-weight: 700;
    padding: 6px 12px;
    color: $black;
    background-color: $white;
    position: absolute;
    top: 18px;
    right: 10px;
    border-radius: 4px;
    &:hover {
        outline: 0;
        background-color: $white;
        color: $primary;
    }
    &:focus {
        outline: 0;
        background-color: $white;
        color: $primary;
    }
    &:active {
        outline: 0;
        background-color: $white;
        color: $primary;
    }
}

.content-section {
    padding: 40px;
}

.content-section--paste {
    margin-top: 60px;
    background-color: $gray-200;
    h1 {
        font-size: 12px;
        color: $gray-600;
        text-transform: uppercase;
    }
}

.copy-content {
    position: relative;
    margin-bottom: 30px;
}

.copy-content-height {
    overflow: auto;
    max-height: 300px;
    border-radius: 6px;
}

.bd-example-modal.modal {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1;
    display: block;
}

.docs-example {
    .tab-example-design {
        padding: 1.25rem;
        border: 1px solid #efeef1;
        border-radius: 6px;
        background-color: $white;
        &::after {
            display: block;
            clear: both;
            content: "";
        }
    }
    .nav.nav-pills.nav-example {
        .nav-item {
            .nav-link {
                border-radius: 4px;
                padding: 5px 15px;
                color: #59536D;
                margin-right: 5px;
                font-size: 13px;
                line-height: 21px;
                font-family: "Muli", "sans-serif;";
                text-transform: uppercase;
                background: #e0e0e9;
                font-weight: 700;
            }
            .nav-link.active {
                color: $white;
                background-color: $gray-700;
                border-color: $gray-700;
            }
            .show {
                >.nav-link {
                    color: $white;
                    background-color: $gray-700;
                    border-color: $gray-700;
                }
            }
        }
    }
}

// scrollspy
.scrollspy-example {
    position: relative;
    height: 200px;
    margin-top: 0.5rem;
    overflow: auto;
}

.scrollspy-example-2 {
    position: relative;
    height: 350px;
    overflow: auto;
}

// toast
.toast.hide {
    display: none;
}

// Docs footer
.docs-footer {
    border-top: 1px solid rgba(152, 166, 173, 0.2);
    padding: 8px 8px 8px;
    color: $gray-700;
    background-color: $white;
    margin-left: 220px;
    border-top: 1px solid #efeef1;
    position: relative;
    bottom: -10px;
    p {
        margin-bottom: 0px;
        font-size: 14px;
        line-height: 30px;
    }
    .docs-footer-links {
        a {
            color: $gray-700;
            margin-left: 1.5rem;
            -webkit-transition: all 0.4s;
            transition: all 0.4s;
            font-size: 14px;
            line-height: 18px;
            color: $black;
            text-decoration: none;
        }
    }
}

.slider-element-v1 {
    margin-bottom: 30px;
}

.docs-footer-text {
    margin-bottom: 0px;
}

// color swatch
.color-swatch {
    height: 214px;
    margin-bottom: 30px;
}

.color-swatch-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 135px;
}

.color-swatch-body {
    border: 1px solid #e6e6e6;
    background-color: $white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px 16px;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.color-swatch-body-label {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 3px;
}

.color-swatch-body-value {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0px;
}

// Docs table
.doc-table {
    .table {
        th {
            padding: 20px 10px;
            vertical-align: top;
            border-top: 1px solid #efeef1;
        }
        td {
            padding: 20px 10px;
            vertical-align: top;
            border-top: 1px solid #efeef1;
        }
    }
}

// Docs border utils
.docs-example-border-utils {
    [class^="border"] {
        display: inline-block;
        width: 5rem;
        height: 5rem;
        margin: 0.25rem;
        background-color: $gray-100;
    }
}

.docs-example-border-utils-0 {
    [class^="border"] {
        border: 1px solid #efeef1;
    }
}

:focus {
    outline: 0;
}

// breakpoints
@media only screen and (max-width:768px) {
    .docs-content {
        padding: 40px 0px 140px 20px;
    }
}

@media only screen and (max-width: 480px) {
    .docs-nav-sidebar {
        .top-search-bar {
            padding: 20px;
            border-bottom: 1px solid #e9e9f1;
            margin-top: 46px;
        }
        .navbar-toggler {
            background-color: $white;
        }
        .navbar {
            padding: 14px;
        }
        width: 100%;
        position: relative;
        top: 56px;
        right: 0px;
    }
    .docs-wrapper {
        margin-left: 0px;
    }
    .docs-footer {
        margin-left: 0px;
    }
    .docs-main-wrapper {
        .main-content {
            margin-left: 0px;
            width: auto;
        }
    }
    .docs-content {
        padding: 30px 0px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .docs-content {
        padding: 40px 0px 140px 60px;
    }
}