// 
// user-variables.scss
// Use this to overwrite Bootstrap and Coach variables
//
// Example of a variable override to change Coach primary color
// Remove the "//" to comment it in and see it in action!
// $primary: #000;

$font-family-sans-serif: "PT Serif",
"serif;" !default;
$font-family-monospace: "PT Serif",
"serif;" !default;
$font-family-base: $font-family-sans-serif !default;
$font-family-dm-serif: "PT Serif Display",
"serif;" !default;


