//Extended from bootstrap border 
@for $i from 2 through 5 {
    .border-#{$i} {
        border-width: 0px + $i !important;
    }
    .border-top-#{$i} {
        border-top-width: 0px + $i !important;
    }
    .border-bottom-#{$i} {
        border-bottom-width: 0px + $i !important;
    }
    .border-left-#{$i} {
        border-left-width: 0px + $i !important;
    }
    .border-right-#{$i} {
        border-right-width: 0px + $i !important;
    }
}