// extended from bootstrap
.custom-breadcrumb {
    .breadcrumb {
        background-color: transparent;
        padding: 0rem;
        font-size: 14px;
        color: $gray-300;
        margin-bottom: 0rem;
    }
    .breadcrumb-item {
        a {
            color: $gray-300;
        }
        +.breadcrumb-item {
            padding-left: .2rem;
            &::before {
                padding-right: .2rem;
                color: $gray-300;
                content: "\e930";
                font-family: 'Feather';
            }
        }
        &.active {
            color: $white;
        }
    }
}