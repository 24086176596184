//  fonts
$font-size: font !default;
@for $i from 12 through 48 {
    .#{$font-size}-#{$i} {
        font-size: 0px + $i;
    }
}

// Text base color 
.text-base {
    color: $body-color;
}

// Semi bold font weight
.font-weight-semi-bold {
    font-weight: $font-weight-semi-bold;
}

.font-weight-medium {
    font-weight: $font-weight-medium;
}

.font-dm-serif {
    font-family: $font-family-dm-serif;
}

.text-inherit {
    color: inherit;
}