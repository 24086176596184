/* Inputmask */

@keyframes blink {
    from,
    to {
        border-right-color: black;
    }
    50% {
        border-right-color: transparent;
    }
}

mark.im-caret {
    animation: 1s blink step-end infinite !important;
}

mark.im-caret-select {
    background-color: rgba(0, 0, 0, 0.25);
}

span.im-static {
    color: $gray-400;
}

div.im-colormask {
    display: inline-block;
    border-style: inset;
    border-width: 2px;
    appearance: textfield;
    cursor: text;
    >input {
        position: absolute !important;
        display: inline-block;
        background-color: transparent;
        color: transparent;
        -webkit-text-fill-color: transparent;
        transition: background-color 5000s ease-in-out 0s;
        caret-color: transparent;
        text-shadow: none;
        appearance: caret;
        border-style: none;
        left: 0;
        &:-webkit-autofill {
            position: absolute !important;
            display: inline-block;
            background-color: transparent;
            color: transparent;
            -webkit-text-fill-color: transparent;
            transition: background-color 5000s ease-in-out 0s;
            caret-color: transparent;
            text-shadow: none;
            appearance: caret;
            border-style: none;
            left: 0;
            &~div {
                background-color: rgb(250, 255, 189);
            }
        }
        &:focus {
            outline: none;
        }
        &::selection {
            background: none;
        }
        &::-moz-selection {
            background: none;
        }
    }
    >div {
        color: $black;
        display: inline-block;
        width: 100px;
    }
}

[im-insert="false"] {
    caret-color: red;
    caret-shape: block;
}